/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Seo = ({ description, title, metaImage }) => {
  const { site } = useStaticQuery(
    graphql`
        query {
            site {
                siteMetadata {
                    siteUrl
                    title
                    description
                    author
                    keywords
                }
            }
        }
    `
  )

  description = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  const image =
    metaImage && metaImage.src
      ? `${site.siteMetadata.siteUrl}${metaImage.src}`
      : null

  return (
    <>
      <GatsbySeo
        title={title}
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        description={description}
        metaTags={[
          {
            property: "keywords",
            name: "keywords",
            content: site.siteMetadata.keywords.join(","),
          },
        ]}
        openGraph={{
          title,
          description,
          images: image
            ? [
              {
                url: image,
                width: metaImage.width,
                height: metaImage.height,
                alt: title,
              },
            ]
            : [],
        }}
      />
    </>
  )
}

Seo.defaultProps = {
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  metaImage: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
}

export default Seo
